<template>





          <div ref="sceneContainer" class="scene-container">

          <div  v-show="showLayer" class="map-overlay" @click.self="showLayer = false">
            <div class="map-layer-content">

              <div ref="tankContainer"
                   style="width:500px;height:400px">
              </div>

            </div>



          </div>

            <BRow>
              <BCol>
                <BButton @click="showLayer=true">show modal </BButton>
                <BButton @click="showLayer=false">close modal </BButton>
              </BCol>
            </BRow>

          </div>








</template>


<style>
.map-parent-div {
  position: relative; /* 부모 div를 상대 위치 기준으로 설정 */
  width: 80%;
  height: 400px;
  margin: 50px auto;
  border: 2px solid #2c3e50;
  text-align: center;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* 배경 클릭을 허용하지 않음 */

}

.map-layer-content {
  background: rgba(0, 255, 120, 0.35); /* 반투명 흰색 배경 */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  pointer-events: auto; /* 레이어 내용은 클릭 가능 */
  position: absolute;
  width: 500px; /* 너비 400px */
  height: 600px; /* 높이 500px */
  cursor: move; /* 드래그 커서 */
}

</style>

<script>
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export default {
  name: 'ThreeScene',
  mounted() {
    this.initThreeScene();
    // this.createTank();
    this.initTankContainer();
  },
  data() {
    return {
      scene: null,
      tank: null,
      showLayer: false,

      tankContainer: null,
      tankCamera: null,
      tankRenderer: null,
      tankScene: null,
      tankControls: null,


    };
  },

  methods: {

    async initTankContainer() {

      const tankContainer = this.$refs['tankContainer'];
      const tankRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      tankRenderer.setSize(400, 400);

      tankContainer.appendChild(tankRenderer.domElement);


      const tankScene = new THREE.Scene();

      const tankCamera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);

      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(5, 10, 7.5);
      tankScene.add(light);

      const axesHelper = new THREE.AxesHelper(10);
      axesHelper.position.set(0,0,0);
      tankScene.add( axesHelper );


      tankCamera.position.z = 30;

      // OrbitControls
      const controls = new OrbitControls( tankCamera, tankRenderer.domElement);
      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation is enabled
      controls.dampingFactor = 0.25;
      controls.screenSpacePanning = false;
      controls.maxPolarAngle = Math.PI / 2;

      tankCamera.lookAt(0, 0, 0);
      tankRenderer.setSize(400, 400);

      function tankAnimate() {
        requestAnimationFrame(tankAnimate);
        controls.update();
        tankRenderer.render(tankScene, tankCamera);
      }

      tankAnimate();


      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshBasicMaterial({ color: 0xFF0000 });
      const cube = new THREE.Mesh(geometry, material);
      cube.position.set(0,0,0);
      cube.updateMatrix();
      tankScene.add(cube);



    },

    createTank() {
      // Create a 45-degree cylinder shape
      const shape = new THREE.Shape();
      const angleStart = 0;
      const angleEnd = Math.PI / 4; // 45 degrees
      const innerRadius = 1;
      const outerRadius = 2;

      // Create the outer arc
      shape.moveTo(outerRadius, 0);
      shape.arc(0, 0, outerRadius, angleStart, angleEnd, false);
      shape.lineTo(innerRadius * Math.cos(angleEnd), innerRadius * Math.sin(angleEnd));

      // Create the inner arc
      shape.arc(0, 0, innerRadius, angleEnd, angleStart, true);
      shape.lineTo(outerRadius * Math.cos(angleStart), outerRadius * Math.sin(angleStart));

      // Extrude the shape to create the tank geometry
      const extrudeSettings = {
        steps: 1,
        depth: 5,
        bevelEnabled: false
      };
      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide });
      this.tank = new THREE.Mesh(geometry, material);

      // Rotate the tank to stand upright and position it so the bottom is at (0, 0, 0)
      this.tank.rotation.x = Math.PI / 2;
      this.tank.position.y = 2.5;

      // Add to scene
      this.scene.add(this.tank);

    },


    initThreeScene() {

      this.scene = new THREE.Scene();
      const scene = this.scene;

      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.sceneContainer.appendChild(renderer.domElement);

      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(5, 10, 7.5);
      scene.add(light);

      const gridHelper = new THREE.GridHelper(100,100);
      scene.add( gridHelper );

      const axesHelper = new THREE.AxesHelper(10);
      axesHelper.position.set(0,0,0);
      scene.add( axesHelper );




      // GLTF Loader
      const loader = new GLTFLoader();
      loader.load('gltf/tanklorry.glb', (gltf) => {
        const tanklorry = gltf.scene;
        tanklorry.scale.set(0.01, 0.01, 0.01); // Adjust scale if needed
        tanklorry.position.set( 8, 1, 10)
        scene.add(tanklorry);
        camera.position.set(10, 10, 20);

      }, undefined, (error) => {
        console.error('An error happened while loading the GLTF model:', error);
      });




      camera.position.z = 30;

      // OrbitControls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation is enabled
      controls.dampingFactor = 0.25;
      controls.screenSpacePanning = false;
      controls.maxPolarAngle = Math.PI / 2;

      function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      }

      animate();

      window.addEventListener('resize', onWindowResize, false);

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }
    }
  }
}

</script>

<style scoped>
.scene-container {
  width: 100vw;
  height: 100vh;
  display: block;
}
</style>
